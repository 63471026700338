import React, { useState, useCallback, Fragment } from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import Delete from '@material-ui/icons/Delete';
import { IoMdAlert } from 'react-icons/io';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';

import Grid from '@material-ui/core/Grid';
import {
  MdAssignment,
  MdCheck,
  MdClose,
  MdMoreVert,
  MdMoreHoriz,
  MdPerson,
  MdBusiness,
} from 'react-icons/md';
import {
  IconButton,
  Menu,
  MenuItem,
  Popover,
  Typography,
} from '@material-ui/core';
import DeleteButton from '@material-ui/core/Button';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import PopoverComponent from '../Popover';
import { useStyles, TableUi } from './style';
import Button from '../Button/index';
import NoData from '../NoData';
import { ITableDataProps } from './interfaces/index';

const usingStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      padding: theme.spacing(1),
    },
  }),
);

const TableData = ({
  rows,
  columns,
  title,
  showBtn = true,
  showPagination = true,
  totalRows = 0,
  page = 0,
  rowsPerPage = 10,
  buttonTitle = 'Title Button',
  showCheckbox = false,
  totalInterestAmount,
  totalIrfValue,
  totalLiquidAmount,
  handleClickShowDialog,
  handleDownload,
  handleApprovalCheckout,
  handleRejectCheckout,
  handlePageChange,
  handleRowsPerPageChange,
  handleDelete,
  t = undefined,
}: ITableDataProps): JSX.Element => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedRows, setSelectedRows] = React.useState<string[]>([]);
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const [indeterminate, setIndeterminate] = useState<boolean>(false);
  const { t: translate } = useTranslation('checkout');
  const [selectedId, setSelectedId] = useState('');
  const [selectedRow, setSelectedRow] = useState({});

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>, id: string) => {
    setAnchorEl(event.currentTarget);
    setSelectedId(id);
    const row = rows.filter((doc) => doc.id === id)?.[0] || {};
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    if (handlePageChange) {
      handlePageChange(newPage);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (handleRowsPerPageChange) handleRowsPerPageChange(+event.target.value);
  };

  const getKeyValue = (key: string) => (obj: Record<string, any>) => obj[key];

  const handleSelectAllClick = useCallback(
    (id: string) => {
      const selects: string[] = [...selectedRows];

      if (selects.includes(id)) {
        const remove = selects.indexOf(id);
        selects.splice(remove, 1);
      } else {
        selects.push(id);
      }

      setSelectedRows([...selects]);

      const result = rows.filter((doc) => doc.installment !== '001');

      setAllSelected(selects.length === result.length);
      setIndeterminate(selects.length > 0 && selects.length !== result.length);
    },
    [rows, selectedRows],
  );

  const handleSelectAll = useCallback(() => {
    const selected: string[] = [];

    rows
      .filter((doc) => doc.installment !== '001' && doc.correlationId)
      .map((doc) => selected.push(doc.correlationId));

    setSelectedRows(selected.length === selectedRows.length ? [] : selected);
    setAllSelected(selected.length !== selectedRows.length);
    setIndeterminate(false);
  }, [rows, selectedRows]);

  const sendSelectedRows = () => {
    if (totalRows === selectedRows.length) {
      Swal.fire({
        title: 'Alerta!',
        html: `
        Você selecionou faturas já parceladas que não podem ser reparceladas no cartão de crédito. <br />
        <br />Gentileza removê-las do checkout ou selecionar outra forma de pagamento. <br />
        Veja os "Detalhes" na lista abaixo. <br /><br />Deseja continuar?
        `,
        icon: 'warning',
        allowOutsideClick: true,
        allowEscapeKey: true,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, remover!',
        cancelButtonText: 'Não',
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (handleDelete) {
            handleDelete(selectedRows);
            setSelectedRows([]);
            setIndeterminate(false);
            setAllSelected(false);
          }
        }
      });
      return;
    }

    if (handleDelete) {
      handleDelete(selectedRows);
      setSelectedRows([]);
      setIndeterminate(false);
      setAllSelected(false);
    }
  };

  const otherClasses = usingStyles();
  const [anchor, setAnchor] = React.useState<HTMLElement | null>(null);
  const [selectedPopoverId, setSelectedPopoverId] = useState('');
  const [popoverText, setPopoverText] = useState('');

  interface PopoverType {
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>;
    id: string;
    documentNumbers?: string[];
    status?: string;
    profile?: string;
    text?: string;
  }

  const handlePopoverOpen = async ({
    event,
    id,
    documentNumbers,
    status,
    profile,
    text,
  }: PopoverType) => {
    setAnchor(event.currentTarget);
    setSelectedPopoverId(id);

    if (documentNumbers) {
      setPopoverText(documentNumbers.join(', '));
    }

    if (status) setPopoverText(status);
    if (profile) setPopoverText(profile);
    if (text) setPopoverText(text);
  };

  const handlePopoverClose = () => {
    setAnchor(null);
    setPopoverText('');
  };

  const statusOpen = Boolean(anchor);

  return (
    <TableUi>
      <div className="header-table">
        <Grid container alignItems="center">
          {title ? (
            <Grid item xs={12} lg={10}>
              <h2>{title}</h2>
            </Grid>
          ) : null}
          {showBtn ? (
            <Grid item xs={12} lg={2}>
              <Button
                text={buttonTitle}
                className="estorno"
                type="button"
                onClick={handleClickShowDialog}
              />
            </Grid>
          ) : null}
        </Grid>
      </div>
      {rows.length > 0 ? (
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) =>
                    column.id === 'selections' ? (
                      <TableCell key={column.id} align={column.align}>
                        <Checkbox
                          onClick={handleSelectAll}
                          indeterminate={indeterminate}
                          checked={allSelected}
                          color="primary"
                          className="checkboxes"
                          disabled={false}
                        />
                      </TableCell>
                    ) : (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ width: column.maxWidth }}
                      >
                        {t !== undefined ? t(column.label) : column.label}
                      </TableCell>
                    ),
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => {
                  return (
                    <TableRow hover key={`${row.id}`}>
                      {columns.map((column) => {
                        const value = getKeyValue(column.id)(row);
                        const { id } = row;
                        if (column.id === 'voucher' && row.voucher) {
                          return (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              className="voucher"
                              onClick={() => {
                                if (handleDownload) handleDownload(value);
                              }}
                            >
                              {value && <MdAssignment size={22} />}
                            </TableCell>
                          );
                        }

                        if (
                          column.id === 'actions' &&
                          ['TRANSFER_WAITING_APPROVAL'].includes(row.status)
                        )
                          return (
                            <TableCell key={`${row.id}`} align={column.align}>
                              <IconButton
                                aria-label="more"
                                id="demo-customized-button"
                                aria-controls={
                                  open ? 'demo-customized-menu' : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={(event) => handleClick(event, id)}
                                disabled={!row.voucher}
                              >
                                <MdMoreVert
                                  color={row.voucher ? '#777777' : '#ACAEAC'}
                                />
                              </IconButton>
                              <Menu
                                id="demo-customized-menu"
                                MenuListProps={{
                                  'aria-labelledby': 'demo-customized-button',
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                              >
                                <MenuItem
                                  onClick={() => {
                                    if (selectedRow && handleApprovalCheckout)
                                      handleApprovalCheckout(selectedRow, true);

                                    handleClose();
                                  }}
                                  disableRipple
                                >
                                  <MdCheck size={18} />
                                  Aprovado – não lançar
                                </MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    if (selectedRow && handleApprovalCheckout)
                                      handleApprovalCheckout(
                                        selectedRow,
                                        false,
                                      );

                                    handleClose();
                                  }}
                                  disableRipple
                                >
                                  <MdCheck size={18} />
                                  Aprovado – lançar
                                </MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    if (handleRejectCheckout)
                                      handleRejectCheckout(selectedId);

                                    handleClose();
                                  }}
                                  disableRipple
                                >
                                  <MdClose size={18} />
                                  Rejeitar
                                </MenuItem>
                              </Menu>
                            </TableCell>
                          );

                        if (
                          column.id === 'selections' &&
                          Number(row.installment) > 1
                        )
                          return (
                            <TableCell key={column.id} align={column.align}>
                              <Checkbox
                                checked={selectedRows.includes(
                                  row.correlationId,
                                )}
                                onClick={() =>
                                  handleSelectAllClick(row.correlationId)
                                }
                                className="checkboxes"
                              />
                            </TableCell>
                          );

                        if (column.id === 'taxes')
                          return (
                            <TableCell key={column.id} align={column.align}>
                              <Tooltip
                                arrow
                                placement="top"
                                title={row.titleIrf ? row.titleIrf : ''}
                              >
                                <div
                                  style={{ width: '100%', display: 'block' }}
                                >
                                  <IoMdAlert
                                    size={24}
                                    style={{ cursor: 'default' }}
                                  />
                                </div>
                              </Tooltip>
                            </TableCell>
                          );

                        if (column.id === 'reportDetails')
                          return (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              className="report-details"
                              onClick={() => {
                                if (handleDownload) handleDownload(value);
                              }}
                            >
                              {value && <MdAssignment size={22} />}
                            </TableCell>
                          );

                        if (column.id === 'documentNumbers')
                          return (
                            <Fragment key={column.id}>
                              <TableCell align={column.align}>
                                <IconButton
                                  aria-owns={
                                    statusOpen
                                      ? `mouse-over-popover`
                                      : undefined
                                  }
                                  aria-haspopup="true"
                                  onMouseEnter={(event) =>
                                    handlePopoverOpen({
                                      event,
                                      id: row.id,
                                      documentNumbers: row.documentNumbers,
                                    })
                                  }
                                  onMouseLeave={handlePopoverClose}
                                >
                                  <MdMoreHoriz color="#777777" />
                                </IconButton>
                              </TableCell>
                              <Popover
                                id="mouse-over-popover"
                                className={otherClasses.popover}
                                classes={{
                                  paper: otherClasses.paper,
                                }}
                                open={
                                  statusOpen && selectedPopoverId === row.id
                                }
                                anchorEl={anchor}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'left',
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'left',
                                }}
                                onClose={handlePopoverClose}
                                disableRestoreFocus
                              >
                                <Typography>{popoverText}</Typography>
                              </Popover>
                            </Fragment>
                          );

                        if (column.id === 'status') {
                          return (
                            <Fragment key={column.id}>
                              <TableCell align={column.align}>
                                <IconButton
                                  aria-owns={
                                    statusOpen
                                      ? `mouse-over-popover`
                                      : undefined
                                  }
                                  aria-haspopup="true"
                                  onMouseEnter={(event) =>
                                    handlePopoverOpen({
                                      event,
                                      id: row.id,
                                      text: row.status,
                                    })
                                  }
                                  onMouseLeave={handlePopoverClose}
                                >
                                  <PopoverComponent status={row.status} />
                                </IconButton>
                              </TableCell>
                              <Popover
                                id="mouse-over-popover"
                                className={otherClasses.popover}
                                classes={{
                                  paper: otherClasses.paper,
                                }}
                                open={
                                  statusOpen && selectedPopoverId === row.id
                                }
                                anchorEl={anchor}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'left',
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'left',
                                }}
                                onClose={handlePopoverClose}
                                disableRestoreFocus
                              >
                                <Typography>{popoverText}</Typography>
                              </Popover>
                            </Fragment>
                          );
                        }

                        if (column.id === 'beneficiaryAddressOne') {
                          return (
                            <Fragment key={column.id}>
                              <TableCell align={column.align}>
                                <IconButton
                                  aria-owns={
                                    statusOpen
                                      ? `mouse-over-popover`
                                      : undefined
                                  }
                                  aria-haspopup="true"
                                  onMouseEnter={(event) =>
                                    handlePopoverOpen({
                                      event,
                                      id: row.id,
                                      text: row.beneficiaryAddressOne,
                                    })
                                  }
                                  onMouseLeave={handlePopoverClose}
                                >
                                  <MdBusiness color="#4862A5" />
                                </IconButton>
                              </TableCell>
                              <Popover
                                id="mouse-over-popover"
                                className={otherClasses.popover}
                                classes={{
                                  paper: otherClasses.paper,
                                }}
                                open={
                                  statusOpen && selectedPopoverId === row.id
                                }
                                anchorEl={anchor}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'left',
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'left',
                                }}
                                onClose={handlePopoverClose}
                                disableRestoreFocus
                              >
                                <Typography>{popoverText}</Typography>
                              </Popover>
                            </Fragment>
                          );
                        }

                        if (column.id === 'userLogin')
                          return (
                            <>
                              <TableCell key={column.id} align={column.align}>
                                <IconButton
                                  aria-owns={
                                    statusOpen
                                      ? `mouse-over-popover`
                                      : undefined
                                  }
                                  onMouseEnter={(event) =>
                                    handlePopoverOpen({
                                      event,
                                      id: row.id,
                                      profile: `${row.userLogin} - ${row.userEmail}`,
                                    })
                                  }
                                  onMouseLeave={handlePopoverClose}
                                >
                                  <MdPerson color="#777777" />
                                </IconButton>
                              </TableCell>
                              <Popover
                                id="mouse-over-popover"
                                className={otherClasses.popover}
                                classes={{
                                  paper: otherClasses.paper,
                                }}
                                open={
                                  statusOpen && selectedPopoverId === row.id
                                }
                                anchorEl={anchor}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'left',
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'left',
                                }}
                                onClose={handlePopoverClose}
                                disableRestoreFocus
                              >
                                <Typography>{popoverText}</Typography>
                              </Popover>
                            </>
                          );
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {showCheckbox ? (
            <TableRow className="details-total">
              <Grid item xs={12} lg={2} className="select-all">
                {selectedRows.length > 0 ? (
                  <DeleteButton
                    onClick={sendSelectedRows}
                    className="delete-button"
                  >
                    <Delete className="delete-icon" />
                  </DeleteButton>
                ) : null}
              </Grid>
              <Grid className="totals">
                <td>
                  {totalInterestAmount !== '$0.00'
                    ? `Total: ${totalInterestAmount}`
                    : ''}
                </td>
                <td>
                  {totalIrfValue
                    ? `${translate('Total IRF Value')} ${totalIrfValue}`
                    : ''}
                </td>
                <td>
                  {totalLiquidAmount
                    ? `${translate('Total Liquid Amount')} ${totalLiquidAmount}`
                    : ''}
                </td>
              </Grid>
            </TableRow>
          ) : null}
          {showPagination ? (
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={totalRows}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              nextIconButtonText={t !== undefined ? t('globals:Next') : 'Next'}
              backIconButtonText={
                t !== undefined ? t('globals:Previous') : 'Previous'
              }
              labelRowsPerPage={
                t !== undefined
                  ? t('globals:Records per page')
                  : 'Records per page: '
              }
            />
          ) : null}
        </Paper>
      ) : (
        <NoData text="Sem registro" />
      )}
    </TableUi>
  );
};

export default TableData;
