import { saveAs } from 'file-saver';
import React, { useState, useEffect, useCallback } from 'react';
import { format } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';

import { ContainerPage, TitleBox } from './style';
import BoxShadow from '../../../../components/BoxShadow/index';
import Button from '../../../../components/Button';
import Cards from '../../../../components/Cards';
import TableApprovals from '../../../../components/Table';
import SuggestionsServices from '../../../../services/sugestions/SugestionsService';
import Loading from '../../../../components/Loading/index';
import CheckoutApprovalsService, {
  ICheckoutApprovalsSearchProps,
} from '../../../../services/approvals/CheckoutApprovalsService';
import { IRows } from '../../../../components/Table/interfaces';

interface IApplicationsData {
  application: string;
}

interface IDecisionsData {
  decision: string;
}

interface IsalesOrganizationsData {
  salesOrganization: string;
}

interface IReportColumn {
  id:
    | 'transactionNumber'
    | 'status'
    | 'application'
    | 'paymentAmount'
    | 'userLogin'
    | 'userEmail'
    | 'salesOrganization'
    | 'id'
    | 'paidAt'
    | 'paymentCurrent'
    | 'voucher'
    | 'actions'
    | 'customer'
    | 'documentNumbers'
    | 'bankAt'
    | 'creditDocument';
  label: string;
  minWidth?: string;
  align?: 'right';
}

const columns: IReportColumn[] = [
  { id: 'id', label: 'Checkout ID' },
  { id: 'transactionNumber', label: 'Transaction ID' },
  { id: 'customer', label: 'Customer' },
  { id: 'documentNumbers', label: 'Documents' },
  { id: 'creditDocument', label: 'Credit Document' },
  { id: 'paidAt', label: 'Paid in' },
  { id: 'bankAt', label: 'Bank Entry Date' },
  { id: 'paymentCurrent', label: 'Currency' },
  { id: 'status', label: 'Status' },
  { id: 'application', label: 'Application' },
  { id: 'paymentAmount', label: 'Total' },
  { id: 'userLogin', label: 'User Login' },
  { id: 'salesOrganization', label: 'Sales' },
  { id: 'voucher', label: 'Voucher' },
  { id: 'actions', label: 'Ações' },
];

const Approvals = (): JSX.Element => {
  const { t } = useTranslation('approvals');

  const initialPage = parseInt(process.env.REACT_APP_INITIAL_PAGE || '0', 10);
  const initialRowsPerPage = parseInt(
    process.env.REACT_APP_ROWS_PER_PAGE || '10',
    10,
  );

  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(true);
  const [notClearing, setNotClearing] = useState(true);
  const [checkouts, setCheckouts] = useState([]);
  const [checkoutId, setCheckoutId] = useState('');
  const [bankInformation, setBankInformation] = useState('');
  const [bankInformations, setBankInformations] = useState([]);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [bankAccount, setBankAccount] = useState('');
  const [bankAccountFee, setBankAccountFee] = useState('');
  const [bankEntryDate, setBankEntryDate] = useState<Date | null>(null);
  const [clearingDocument, setClearingDocument] = useState('');
  const [totalApprovals, setTotalApprovals] = useState(0);
  const [totalReject, setTotalReject] = useState(0);
  const [totalAwaitingApproval, setTotalAwaitingApproval] = useState(0);
  const [totalAwaitingReceipt, setTotalAwaitingReceipt] = useState(0);
  const [totalCreated, setTotalCreated] = useState(0);

  const [page, setPage] = useState(initialPage);
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
  const [selectedDateInicial, setSelectedDateInicial] = useState<Date | null>(
    null,
  );
  const [selectedDateFinal, setSelectedDateFinal] = useState<Date | null>(null);
  const [selectApplication, setSelectApplication] = useState('');
  const [selectSalesOrganization, setSelectSalesOrganization] = useState('');
  const [selectStatus, setStatus] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [applicationSuggestions, setApplicationSuggestions] = useState<
    IApplicationsData[]
  >([]);
  const [decisionsSuggestions, setDecisionsSuggestions] = useState<
    IDecisionsData[]
  >([]);
  const [salesOrganizationsSuggestions, setSalesOrganizationsSuggestions] =
    useState<IsalesOrganizationsData[]>([]);
  const [selectedDocumentNumber, setSelectedDocumentNumber] = useState('');
  const [selectedTransactionNumber, setSelectedTransactionNumber] =
    useState('');

  const [openDialog, setOpenDialog] = useState(false);
  const [openNotClearing, setOpenDialogNotClearing] = useState(false);

  const fetchCheckoutApprovalsData = (obj?: ICheckoutApprovalsSearchProps) => {
    CheckoutApprovalsService.search({
      application: obj?.application,
      status: obj?.status,
      salesOrganization: obj?.salesOrganization,
      initialCreation: obj?.initialCreation
        ? format(new Date(obj?.initialCreation), 'yyyy-MM-dd')
        : undefined,
      finalCreation: obj?.finalCreation
        ? format(new Date(obj?.finalCreation), 'yyyy-MM-dd')
        : undefined,
      page: obj?.page,
      limit: obj?.limit,
      customer: obj?.customer,
      document: obj?.document,
      transactionNumber: obj?.transactionNumber,
    }).then((r) => {
      setCheckouts(r.data.body.checkouts);
      setBankInformations(r.data.body.bankInformations);
      setTotalCreated(parseInt(r.data.body.numbers.totalCreated, 10));
      setTotalApprovals(r.data.body.numbers.totalApprovals);
      setTotalAwaitingApproval(r.data.body.numbers.totalAwaitingApproval);
      setTotalAwaitingReceipt(r.data.body.numbers.totalAwaitingReceipt);
      setTotalReject(r.data.body.numbers.totalReject);
      setCheckoutId('');
      setBankAccount('');
      setBankAccountFee('');
      setClearingDocument('');
      setBankInformation('');
      setNotClearing(true);
      setLoading(false);
    });
  };

  const handleRowsPerPageChange = (rowsPerPageChange: number) => {
    setRowsPerPage(rowsPerPageChange);
    fetchCheckoutApprovalsData({
      application: selectApplication,
      status: selectStatus,
      salesOrganization: selectSalesOrganization,
      initialCreation: (selectedDateInicial || '').toString(),
      finalCreation: (selectedDateFinal || '').toString(),
      page,
      limit: rowsPerPageChange,
      customer: (selectedCustomer || '').toString(),
      document: (selectedDocumentNumber || '').toString(),
      transactionNumber: (selectedTransactionNumber || '').toString(),
    });
  };

  const handlePageChange = (pageChange: number) => {
    setPage(pageChange);
    fetchCheckoutApprovalsData({
      application: selectApplication,
      status: selectStatus,
      salesOrganization: selectSalesOrganization,
      initialCreation: (selectedDateInicial || '').toString(),
      finalCreation: (selectedDateFinal || '').toString(),
      page: pageChange,
      limit: rowsPerPage,
      customer: (selectedCustomer || '').toString(),
      document: (selectedDocumentNumber || '').toString(),
      transactionNumber: (selectedTransactionNumber || '').toString(),
    });
  };

  const handleDateChangeInicial = (date: any) => {
    setSelectedDateInicial(date);
  };

  const handleDateChangeFinal = (date: any) => {
    setSelectedDateFinal(date);
  };

  const handleChangeApplication = (
    event: React.ChangeEvent<{ value: any }>,
  ) => {
    setSelectApplication(event.target.value);
  };

  const handleChangeSalesOrganization = (
    event: React.ChangeEvent<{ value: any }>,
  ) => {
    setSelectSalesOrganization(event.target.value);
  };

  const handleChangeStatus = (event: React.ChangeEvent<{ value: any }>) => {
    setStatus(event.target.value);
  };

  const handleChangeCustomer = (event: any) => {
    setSelectedCustomer(event.target.value);
  };

  const handleChangeDocumentNumber = (event: any) => {
    setSelectedDocumentNumber(event.target.value);
  };

  const handleChangeTransactionNumber = (event: any) => {
    setSelectedTransactionNumber(event.target.value);
  };

  const handleSubmitFilter = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    setPage(initialPage);
    fetchCheckoutApprovalsData({
      application: selectApplication,
      status: selectStatus,
      salesOrganization: selectSalesOrganization,
      initialCreation: (selectedDateInicial || '').toString(),
      finalCreation: (selectedDateFinal || '').toString(),
      page: initialPage,
      limit: rowsPerPage,
      customer: (selectedCustomer || '').toString(),
      document: (selectedDocumentNumber || '').toString(),
      transactionNumber: (selectedTransactionNumber || '').toString(),
    });
  };

  const handleClearValues = () => {
    setLoading(true);
    setPage(initialPage);
    setSelectedDateInicial(null);
    setSelectedDateFinal(null);
    setSelectApplication('');
    setSelectSalesOrganization('');
    setStatus('');
    setSelectedCustomer('');
    setSelectedDocumentNumber('');
    setSelectedTransactionNumber('');
    fetchCheckoutApprovalsData();
  };

  const handleDownloadVoucher = (voucher: string) => {
    CheckoutApprovalsService.showVoucher({
      filename: voucher,
    })
      .then((r) => {
        if (r.data.body.ContentLength === 0) {
          enqueueSnackbar(t('No data was found to generate the voucher.'), {
            variant: 'warning',
          });
          return;
        }
        const { buffer } = new Uint8Array(r.data.body);
        saveAs(new Blob([buffer]), `voucher-${voucher}`);
      })
      .catch((_) => {
        enqueueSnackbar(t('No was found the voucher.'), {
          variant: 'error',
        });
      });
  };

  const handleDownloadReport = () => {
    CheckoutApprovalsService.download({
      application: selectApplication,
      status: selectStatus,
      salesOrganization: selectSalesOrganization,
      initialCreation: (selectedDateInicial || '').toString(),
      finalCreation: (selectedDateFinal || '').toString(),
      customer: (selectedCustomer || '').toString(),
      document: (selectedDocumentNumber || '').toString(),
      transactionNumber: (selectedTransactionNumber || '').toString(),
    }).then((r) => {
      if (r.data.size === 0) {
        enqueueSnackbar(t('No data was found to generate the report.'), {
          variant: 'warning',
        });
        return;
      }
      const type =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      saveAs(new Blob([r.data], { type }), 'report.xlsx');
    });
  };
  const handleChangeBankAccount = (
    event: React.ChangeEvent<{ value: any }>,
  ) => {
    setBankAccount(event.target.value);
  };

  const handleBankInformation = (event: React.ChangeEvent<{ value: any }>) => {
    const bank = bankInformations.filter(
      (item: any) => item.id === event.target.value,
    )[0] as any;

    setBankInformation(event.target.value);

    if (!bank.accounts) {
      setBankAccounts([]);
      return;
    }
    setBankAccounts(bank?.accounts);
  };

  const handleClickShowDialog = () => {
    setOpenDialog(!openDialog);
  };

  const handleClickShowDialogNotClearing = () => {
    setOpenDialogNotClearing(!setOpenDialogNotClearing);
  };

  const validateInfomations = (): { message: string }[] | [] => {
    const errors = [];

    if (!checkoutId) {
      errors.push({ message: 'Not Found Checkout ID' });
    }

    if (!bankInformation && !notClearing) {
      errors.push({ message: 'Bank Information is required' });
    }

    if (!bankAccount && !notClearing) {
      errors.push({ message: 'Bank Account is required' });
    }

    if (!bankAccountFee && !notClearing) {
      errors.push({ message: 'Bank Account Fee is required' });
    }

    if (
      bankAccountFee &&
      bankAccountFee.length > 0 &&
      !bankAccountFee.match(/^\d+\.\d{2}$/)
    ) {
      errors.push({
        message: 'Bank Account Fee is invalid value format (00.00)',
      });
    }

    if (
      clearingDocument &&
      clearingDocument.length > 0 &&
      !clearingDocument.match(/^[A-Za-z0-9]+;[0-9]{4};[0-9]+$/g)
    ) {
      errors.push({
        message:
          'Clearing Document is invalid value format (Sales;Year;Document)',
      });
    }

    return errors;
  };

  const handleSubmitApproval = () => {
    const validate = validateInfomations();

    if (validate.length > 0) {
      validate.map((item) => {
        return enqueueSnackbar(t(item.message), {
          variant: 'error',
        });
      });
      return;
    }

    const data = {
      id: checkoutId,
      bankAccount,
      bankAccountFee,
      bankInformation,
      bankAt: bankEntryDate,
      notClearing,
      creditDocument: clearingDocument,
    };

    CheckoutApprovalsService.approvalCheckout(data)
      .then((_) => {
        enqueueSnackbar(t('This Checkout is Aproved'), {
          variant: 'success',
        });
        fetchCheckoutApprovalsData();
        if (notClearing) {
          handleClickShowDialogNotClearing();
        } else {
          handleClickShowDialog();
        }
      })
      .catch((_) => {
        enqueueSnackbar(t('This Checkout Already Aproved/Reject'), {
          variant: 'warning',
        });
      });
  };

  const handleApprovalCheckout = useCallback(
    (values: IRows, notClearing: boolean) => {
      const banks = bankInformations.filter(
        (item: any) =>
          item.salesOrganization === values.salesOrganization &&
          item.currency === values.paymentCurrent,
      );

      const bank = banks.length > 0 ? (banks[0] as any) : null;

      if (!bank) {
        enqueueSnackbar(t('No bank found for this checkout.'), {
          variant: 'error',
        });
        return;
      }

      if (bank.accounts && bank.accounts.length === 0) {
        enqueueSnackbar(t('No bank account found for this checkout.'), {
          variant: 'error',
        });
        return;
      }

      setCheckoutId(values.id);
      setBankInformation(bank.id);
      setBankAccounts(bank.accounts);
      setNotClearing(notClearing);

      if (notClearing) {
        setOpenDialogNotClearing(true);
      } else {
        setOpenDialog(true);
      }
    },
    [bankInformations, enqueueSnackbar, t],
  );

  const handleRejectCheckout = (id: string) => {
    CheckoutApprovalsService.rejectCheckout({ id })
      .then((_) => {
        enqueueSnackbar(t('This Checkout is Rejected'), {
          variant: 'success',
        });
        fetchCheckoutApprovalsData();
      })
      .catch((_) => {
        enqueueSnackbar(t('This Checkout Already Aproved/Reject'), {
          variant: 'warning',
        });
      });
  };

  const handleBankAccountFee = (fee: string) => {
    if (fee.split(',').length > 1) return;
    if (fee.split('.').length > 2) return;
    if (fee.split('.').length > 1 && fee.split('.')[1].length > 2) return;
    setBankAccountFee(fee);
  };

  const handleBankEntryDate = (date: any) => {
    setBankEntryDate(date);
  };

  const handleClearingDocument = (document: string) => {
    setClearingDocument(document);
  };

  useEffect(() => {
    SuggestionsServices.getApplicationsSuggestionsApprovals().then(
      (response) => {
        const { applications, decisions, salesOrganizations } =
          response.data.body;
        setApplicationSuggestions(applications);
        setDecisionsSuggestions(decisions);
        setSalesOrganizationsSuggestions(salesOrganizations);
      },
    );

    fetchCheckoutApprovalsData({
      page: parseInt(process.env.REACT_APP_INITIAL_PAGE || '0', 10),
      limit: parseInt(process.env.REACT_APP_ROWS_PER_PAGE || '10', 10),
    });
  }, []);

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ContainerPage>
          <Grid container>
            <Grid item xs={12}>
              <form onSubmit={handleSubmitFilter}>
                <BoxShadow>
                  <Grid container>
                    <Grid item xs={12}>
                      <TitleBox>Filtros</TitleBox>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={4} xl={3}>
                      <FormControl className="style-select">
                        <InputLabel
                          id="select-application"
                          className="input-label"
                        >
                          {t('Application')}
                        </InputLabel>
                        <Select
                          labelId="select-application"
                          id="select-application-input"
                          value={selectApplication}
                          onChange={handleChangeApplication}
                        >
                          {applicationSuggestions.map((item) => (
                            <MenuItem
                              key={item.application}
                              value={item.application}
                            >
                              {item.application.toUpperCase()}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} xl={3}>
                      <FormControl className="style-select">
                        <InputLabel
                          id="select-sales-organization"
                          className="input-label"
                        >
                          {t('Sales Organization')}
                        </InputLabel>
                        <Select
                          labelId="select-sales-organization"
                          id="select-sales-organization-input"
                          value={selectSalesOrganization}
                          onChange={handleChangeSalesOrganization}
                        >
                          {salesOrganizationsSuggestions.map((item) => (
                            <MenuItem
                              key={item.salesOrganization}
                              value={item.salesOrganization}
                            >
                              {item.salesOrganization.toUpperCase()}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} xl={2}>
                      <FormControl className="style-select">
                        <InputLabel id="select-status" className="input-label">
                          {t('Status')}
                        </InputLabel>
                        <Select
                          labelId="select-status"
                          id="select-status-input"
                          value={selectStatus}
                          onChange={handleChangeStatus}
                        >
                          {decisionsSuggestions.map((item) => (
                            <MenuItem key={item.decision} value={item.decision}>
                              {item.decision.toUpperCase()}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      lg={4}
                      xl={2}
                      className="date-picker-inicial"
                    >
                      <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-inicial"
                        label={t('Initial Date')}
                        format="MM/dd/yyyy"
                        value={selectedDateInicial}
                        onChange={handleDateChangeInicial}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      lg={4}
                      xl={2}
                      sm={2}
                      className="date-picker-final"
                    >
                      <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-final"
                        label={t('Final Date')}
                        format="MM/dd/yyyy"
                        value={selectedDateFinal}
                        onChange={handleDateChangeFinal}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={4} xl={3}>
                      <FormControl className="style-select">
                        <TextField
                          label={t('Customer')}
                          value={selectedCustomer}
                          onChange={handleChangeCustomer}
                          margin="normal"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} xl={3}>
                      <FormControl className="style-select">
                        <TextField
                          label={t('Document Number')}
                          value={selectedDocumentNumber}
                          onChange={handleChangeDocumentNumber}
                          margin="normal"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} xl={3}>
                      <FormControl className="style-select">
                        <TextField
                          label={t('Transaction Number')}
                          value={selectedTransactionNumber}
                          onChange={handleChangeTransactionNumber}
                          margin="normal"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="flex-end" spacing={2}>
                    <Grid item xs={12} lg={3} xl={2}>
                      <Button
                        text={t('Clear')}
                        type="button"
                        className="cancel"
                        onClick={handleClearValues}
                      />
                    </Grid>
                    <Grid item xs={12} lg={3} xl={2}>
                      <Button
                        text={t('Export to Excel')}
                        type="button"
                        className="estorno"
                        onClick={handleDownloadReport}
                      />
                    </Grid>
                    <Grid item xs={12} lg={3} xl={2}>
                      <Button
                        text={t('Search')}
                        type="submit"
                        className="search-report"
                      />
                    </Grid>
                  </Grid>
                </BoxShadow>
              </form>
            </Grid>
          </Grid>

          <Grid container className="mt-16">
            <Grid item xs={12}>
              <Grid container spacing={3} justifyContent="space-between">
                <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                  <Cards
                    text={t('Total Approved')}
                    value={totalApprovals.toString()}
                    className="blue-light"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={2} xl={2}>
                  <Cards
                    text={t('Total Awaiting Approval')}
                    value={totalAwaitingApproval.toString()}
                    className="yellow"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={2} xl={2}>
                  <Cards
                    text={t('Total Awaiting Receipt')}
                    value={totalAwaitingReceipt.toString()}
                    className="yellow"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                  <Cards
                    text={t('Total Rejected')}
                    value={totalReject.toString()}
                    className="red"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={2} xl={2}>
                  <Cards
                    text={t('Total Created')}
                    value={totalCreated.toString()}
                    className="blue-dark"
                  />
                </Grid>
              </Grid>
              <Grid container className="mt-16">
                <Grid item xs={12}>
                  <BoxShadow>
                    {loading ? (
                      <Loading />
                    ) : (
                      <TableApprovals
                        rows={checkouts}
                        columns={columns}
                        showBtn={false}
                        title={t('Results')}
                        handlePageChange={handlePageChange}
                        handleRowsPerPageChange={handleRowsPerPageChange}
                        handleDownload={handleDownloadVoucher}
                        handleApprovalCheckout={handleApprovalCheckout}
                        handleRejectCheckout={handleRejectCheckout}
                        totalRows={totalCreated}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        t={t}
                      />
                    )}
                  </BoxShadow>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ContainerPage>
        <Dialog
          open={openNotClearing}
          onClose={handleClickShowDialogNotClearing}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle id="alert-dialog-title">
            {t('Not Clearing Wire Transfer Title')}
          </DialogTitle>
          <DialogContent>
            <p>{t('Not Clearing Wire Transfer')}</p>
          </DialogContent>
          <DialogActions>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item xs={12} sm={3}>
                <Button
                  text={t('Cancel')}
                  className="cancel"
                  type="button"
                  onClick={handleClickShowDialogNotClearing}
                />
              </Grid>
              <Grid item xs={12} sm={3} style={{ position: 'relative' }}>
                <Button
                  text={t('Create Not Clearing')}
                  className="estorno"
                  type="button"
                  onClick={handleSubmitApproval}
                />
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openDialog}
          onClose={handleClickShowDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle id="alert-dialog-title">
            {t('Approvals Title')}
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <FormControl fullWidth>
                  <InputLabel id="select-bank-account" className="input-label">
                    {t('Bank Accounts Wire Transfer')}
                  </InputLabel>
                  <Select
                    id="input-transaction-code-reversal"
                    className="input-transaction-code-reversal"
                    label={t('Bank Accounts Wire Transfer')}
                    value={bankInformation}
                    onChange={handleBankInformation}
                    fullWidth
                  >
                    {bankInformations.map((item: any) => (
                      <MenuItem key={item?.id} value={item?.id}>
                        {`[${item?.salesOrganization}] [${
                          item?.currency
                        }] ${item?.beneficiaryName.toUpperCase()} (${item?.bankName.toUpperCase()})`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={12}>
                <FormControl fullWidth>
                  <InputLabel id="select-bank-account" className="input-label">
                    {t('Bank Accounts')}
                  </InputLabel>
                  <Select
                    id="input-transaction-code-reversal"
                    className="input-transaction-code-reversal"
                    label={t('Bank Accounts')}
                    value={bankAccount}
                    onChange={handleChangeBankAccount}
                    fullWidth
                  >
                    {bankAccounts.map((item: any) => (
                      <MenuItem key={item?.name} value={item?.account}>
                        {item?.name.toUpperCase()}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={12}>
                <KeyboardDatePicker
                  fullWidth
                  margin="normal"
                  label={t('Date of Bank Entry')}
                  format="MM/dd/yyyy"
                  value={bankEntryDate}
                  onChange={handleBankEntryDate}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </Grid>
              <Grid item md={12}>
                <FormControl fullWidth>
                  <TextField
                    name="clearing-document"
                    label={t('Clearing Document')}
                    type="text"
                    value={clearingDocument}
                    className="input-admin-custom"
                    onChange={(input) =>
                      handleClearingDocument(input.target.value)}
                    fullWidth
                  />
                  <p style={{ color: 'gray' }}>
                    Por favor, digite os valores separando os por ponto e
                    virgula (;). Ex. SJK1;2024;0000000
                  </p>
                </FormControl>
              </Grid>
              <Grid item md={12}>
                <FormControl fullWidth>
                  <TextField
                    name="bank-fee"
                    label={t('Bank Account Fee')}
                    type="text"
                    value={bankAccountFee}
                    className="input-admin-custom"
                    onChange={(input) =>
                      handleBankAccountFee(input.target.value)}
                    fullWidth
                  />
                  <p style={{ color: 'gray' }}>
                    Por favor, digite o valor separando os números decimais com
                    ponto (.) e com duas casas depois da vírgula. Ex. 10.10
                  </p>
                </FormControl>
              </Grid>

              <Grid item md={12}>
                <p style={{ color: 'red' }}>
                  Obs: Valide os valores e o banco em que o cliente fez o wire
                  transfer
                </p>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item xs={12} sm={3}>
                <Button
                  text={t('Cancel')}
                  className="cancel"
                  type="button"
                  onClick={handleClickShowDialog}
                />
              </Grid>
              <Grid item xs={12} sm={3} style={{ position: 'relative' }}>
                <Button
                  text={t('Create')}
                  className="estorno"
                  type="button"
                  onClick={handleSubmitApproval}
                />
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </MuiPickersUtilsProvider>
    </>
  );
};

export default Approvals;
