import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiDotsVerticalRounded } from 'react-icons/bi';

import { IGatewayUserData } from '../CardList';

export interface ICardMenuButton {
  handleDelete(): void;
  handleEdit(): void;
  checkout: IGatewayUserData;
}

export default ({
  handleDelete,
  handleEdit,
  checkout,
}: ICardMenuButton): JSX.Element => {
  const { t } = useTranslation('checkout');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAction = (type: string) => {
    setAnchorEl(null);
    if (type === 'delete') handleDelete();
    if (type === 'edit') handleEdit();
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <BiDotsVerticalRounded color="#B0B0AF" size={25} />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleAction('delete')}>
          {t('Delete')}
        </MenuItem>
        {!/^FivServ/.test(checkout.gateway) &&
          !/^JPMorgan/.test(checkout.gateway) && (
            <MenuItem onClick={() => handleAction('edit')}>
              {t('Edit')}
            </MenuItem>
          )}
      </Menu>
    </div>
  );
};
