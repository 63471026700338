/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import NumberFormat from 'react-number-format';
import cpfValidator from '../../../utils/cpf-validator';
import areObjectValuesDifferent from '../../../utils/are-object-values-different';
import LogosGateway from '../../LogosGateway';
import {
  IGatewayUi,
  ModalFormUi,
  NewCardActionsUi,
  NewCardModalUi,
  TextInputCardNumber,
  TextInputCardName,
  TextInputValidate,
} from './style';
import { ICard } from '../CardList';
import getCardBrand from '../../../utils/get-card-brand';
import { DocumentNumberMasks } from '../../../services/checkout/interfaces/ICheckoutDTO';

import { usePayment } from '../../../hooks/payment';
import { BillingInformation } from '../../../services/payment/interfaces/ICheckoutPaymentDTO';

export interface INewCardModal {
  setOpen(open: boolean): void;
  setGatewayUserCards(value: ICard[]): void;
  handleGoToPayment(cardToPay: ICard): void;

  open: boolean;
  gatewayUserCards: ICard[];
  gateway?: string;
  showBillingForm: boolean;
}

const NewCardModal = ({
  setOpen,
  open,
  gatewayUserCards,
  setGatewayUserCards,
  gateway,
  handleGoToPayment,
  showBillingForm = false,
}: INewCardModal): JSX.Element => {
  const { t } = useTranslation('checkout');
  const snackbar = useSnackbar();

  const [cardNumber, setCardNumber] = useState('');
  const [cardHolderName, setCardHolderName] = useState('');
  const [cardValidate, setCardValidate] = useState('');

  const [fullName, setFullName] = useState('');
  const [documentNumber, setDocumentNumber] = useState('');
  const [address, setAddress] = useState('');
  // const [neighborhood, setNeighborhood] = useState('');
  // const [number, setNumber] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZIP] = useState('');
  const [country, setCountry] = useState('');
  const [documentMask, setDocumentMask] = useState(DocumentNumberMasks.CPF);

  const { numCard, setNumCard, billingInformation, setUserPaymentProfile } =
    usePayment();

  const handleClose = () => {
    setUserPaymentProfile('');
    setNumCard('');
    setOpen(false);
  };

  const handleClear = () => {
    setCardNumber('');
    setCardHolderName('');
    setCardValidate('');
    setFullName('');
    setDocumentNumber('');
    setDocumentMask(DocumentNumberMasks.CPF);
    setAddress('');
    // setNeighborhood('');
    // setNumber('');
    setCity('');
    setState('');
    setZIP('');
    setCountry('');
  };

  const loadBillingData = (billInfo?: BillingInformation) => {
    const fName =
      billInfo?.firstName && billInfo?.lastName
        ? `${billInfo.firstName} ${billInfo.lastName}`
        : '';
    setFullName(fName);
    setDocumentNumber(billInfo?.documentNumber || '');
    setAddress(billInfo?.address || '');
    // setNumber(billInfo?.number || '');
    setCity(billInfo?.city || '');
    setState(billInfo?.state || '');
    setZIP(billInfo?.zip || '');
    setCountry(billInfo?.country || '');
  };

  const validateFields = (dataValidation: [boolean, string][]) => {
    const errors = dataValidation
      .filter((invalid) => !!invalid[0])
      .map((x) => x[0] && x[1]);

    if (!errors.length) return false;

    errors.forEach((e) => {
      snackbar.enqueueSnackbar(e, {
        variant: 'error',
      });
    });
    return true;
  };

  const areFieldsInvalid = () => {
    const billingDataValidation: [boolean, string][] = showBillingForm
      ? [
          [
            fullName.trim().length <= 5,
            t('Full name is required and must have at least six characters'),
          ],
          [
            !/^[a-zA-Z0-9\s-.,']{2,}$/g.test(address.trim()),
            t('Address is required and must have at least two caracters'),
          ],
          // [!neighborhood, t('Neighborhood is required')],
          [!city, t('City is required')],
          [!state, t('State/Province is required')],
          [
            country.trim().length < 4,
            t('Country is required and must have at least four letters'),
          ],
          [
            zip.replace(/\D/g, '').length < 2,
            t(
              'ZIP/Postal Code is required and must have at least four numbers',
            ),
          ],
        ]
      : [];

    if (documentNumber && documentNumber !== '')
      billingDataValidation.push([
        documentNumber.trim().length < 10,
        t('Document is required and must be valid'),
      ]);

    if(/^JPMorgan/i.test(gateway || '')) {
      billingDataValidation.push([
        state.trim().length > 3,
        t('State/Province is abbreviated'),
      ]);
    }

    const validation: [boolean, string][] = !numCard
      ? [
          [!cardNumber, t('Card number is required')],
          [!cardValidate, t('Card validate is required')],
          [
            cardHolderName.length <= 5,
            t(
              'Card holder name is required and must have at least 6 characters',
            ),
          ],
          [
            Number(cardValidate.split('/')[0]) > 12 ||
              Number(cardValidate.split('/')[0]) < 1,
            t('Expiration month must be between 1 and 12.'),
          ],
        ]
      : [];

    return validateFields([...validation, ...billingDataValidation]);
  };

  const handleDocumentChange = useCallback(({ target: { value } }) => {
    const inputValue = value.replace(/[^0-9]/g, '');

    if (inputValue.length < 11) setDocumentMask(DocumentNumberMasks.CPF);

    if (inputValue.length === 11 && !cpfValidator.isValid(inputValue))
      setDocumentMask(DocumentNumberMasks.CNPJ);

    setDocumentNumber(inputValue);
  }, []);

  const handlePaste = useCallback(({ clipboardData }) => {
    const value = clipboardData.getData('Text').replace(/[^0-9]/g, '');

    const mask =
      value.length > 11 ? DocumentNumberMasks.CNPJ : DocumentNumberMasks.CPF;
    setDocumentMask(mask);
  }, []);

  const handleAdd = async () => {
    if (areFieldsInvalid()) return;

    const billInfo = showBillingForm
      ? {
          firstName: fullName.split(' ')[0],
          documentNumber,
          lastName: fullName.split(' ').slice(1).join(' '),
          address,
          city,
          state,
          country,
          zip,
          // number,
        }
      : {};

    let newCard: ICard;
    let cards: ICard[];
    if (!numCard) {
      newCard = {
        card: cardNumber.replace(/\D*/g, ''),
        holderName: cardHolderName,
        validate: cardValidate,
        brand: getCardBrand(cardNumber.replace(/\D*/g, '')),
        billingInformation: { ...billInfo } as BillingInformation,
      };
      cards = [...gatewayUserCards, newCard];
    } else {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      newCard = gatewayUserCards.find((e) => e.card === numCard)!;

      newCard.billingInformation = {
        ...billInfo,
        update: areObjectValuesDifferent(newCard.billingInformation, billInfo),
      } as BillingInformation;

      cards = gatewayUserCards;
    }

    setGatewayUserCards(cards);
    handleGoToPayment(newCard);

    handleClear();
    setOpen(false);
  };

  useEffect(() => {
    loadBillingData(billingInformation);
  }, [billingInformation, open]);

  return (
    <NewCardModalUi>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {!numCard ? t('Add New Card') : t('Update Card')}
        </DialogTitle>
        <DialogContent>
          <ModalFormUi>
            {!numCard ? (
              <>
                <div className="line">
                  <TextInputCardNumber>
                    <NumberFormat
                      className="card-number-c"
                      placeholder="0000 0000 0000 0000"
                      format="#### #### #### ####"
                      onChange={(e: any) => setCardNumber(e.target.value)}
                      value={cardNumber}
                    />
                  </TextInputCardNumber>
                  <TextInputValidate>
                    <NumberFormat
                      className="card-number-f"
                      format="##/####"
                      placeholder={t('MM/YYYY')}
                      mask={['M', 'M', 'Y', 'Y', 'Y', 'Y'].map((e) => t(e))}
                      onChange={(e: any) => setCardValidate(e.target.value)}
                      value={cardValidate}
                    />
                  </TextInputValidate>
                </div>
                <div className="line">
                  <TextInputCardName
                    value={cardHolderName}
                    onChange={(e) => setCardHolderName(e.target.value)}
                    placeholder={t('Name Printed on Card')}
                  />
                </div>
              </>
            ) : (
              <>
                <TextInputCardName
                  className="card-number"
                  value={'XXXX '.repeat(3).concat(numCard.slice(-4))}
                  disabled
                />
              </>
            )}

            {showBillingForm ? (
              <>
                <h3 className="subtitle">{t('Billing Information')}</h3>

                {(!!numCard && !billingInformation) || !numCard ? (
                  <p className="disclaimer">
                    {t(
                      `For your security, the financial gateway or partner bank requests that you fill in the address registered for this card. Kindly insert the data. This filling is requested only once and is subject to analysis by the anti-fraud system.`,
                    )}
                  </p>
                ) : null}

                <div className="line">
                  <TextInputCardName
                    className="full-name"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    placeholder={t('Full Name')}
                  />
                </div>
                {gateway === 'GetnetGateway' ? (
                  <div className="line">
                    <TextInputValidate className="document-container">
                      <NumberFormat
                        className="document"
                        format={documentMask}
                        placeholder={t('Document')}
                        mask="_"
                        onPaste={handlePaste}
                        onChange={handleDocumentChange}
                        value={documentNumber}
                      />
                    </TextInputValidate>
                  </div>
                ) : null}
                <div className="line">
                  <TextInputCardName
                    className="address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    placeholder={t('Address')}
                  />
                </div>
                {/** code above just for SJK1 and BRL - not implemented yet */}
                {/* <div className="line">
                  <TextInputCardName
                    style={{ width: '100%' }}
                    className="address"
                    value={neighborhood}
                    onChange={(e) => setNeighborhood(e.target.value)}
                    placeholder={t('Neighborhood')}
                  />
                  <TextInputCardName
                    style={{ width: '20%', marginLeft: '20px' }}
                    className="number"
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                    placeholder={t('Number')}
                  />
                </div> */}
                <div className="line">
                  <TextInputCardName
                    style={{ marginRight: '20px' }}
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    placeholder={t('City')}
                  />
                  <TextInputCardName
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                    placeholder={t('State/Province')}
                  />
                </div>
                <div className="line">
                  <TextInputCardName
                    style={{ marginRight: '20px' }}
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    placeholder={t('Country')}
                  />
                  <TextInputCardName
                    value={zip}
                    onChange={(e) => setZIP(e.target.value)}
                    placeholder={t('ZIP/Postal Code')}
                  />
                </div>
              </>
            ) : null}
          </ModalFormUi>
          <IGatewayUi>
            {t('Gateway for Payment')}
            <LogosGateway gateway={gateway} />
          </IGatewayUi>
        </DialogContent>
        <DialogActions>
          <NewCardActionsUi>
            <Button variant="contained" onClick={handleClear}>
              {t('Clear')}
            </Button>
            <Button variant="contained" onClick={handleClose}>
              {t('Cancel')}
            </Button>
            <Button variant="contained" color="primary" onClick={handleAdd}>
              {t('Add')}
            </Button>
          </NewCardActionsUi>
        </DialogActions>
      </Dialog>
    </NewCardModalUi>
  );
};

export default NewCardModal;
