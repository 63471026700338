import React, { useState, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';

import Toolbar from '@material-ui/core/Toolbar';

import { FiMenu as MenuIcon } from 'react-icons/fi';

import SidebarLeft from '../../components/Admin/SidebarLeft/index';
import Header from '../../components/Admin/Header/index';
import Home from './pages/Home';
import Reversal from './pages/Reversal';
import Report from './pages/Report/index';
import Webhook from './pages/Webhook';
import Sites from './pages/Sites';

import { useAuth } from '../../hooks/auth';

import { useStyles } from './style';
import Loading from '../../components/Loading/index';
import PaymentCurrency from './pages/PaymentCurrency';
import Gateways from './pages/Gateways';
import Approvals from './pages/Approvals';
import BankInformation from './pages/BankInformation';

const Routes = (): React.ReactElement => {
  const classes = useStyles();

  const [mobileOpen, setMobileOpen] = useState(false);
  const [loadingToken, setLoadingToken] = useState(true);
  const { token } = useAuth();

  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

  const container =
    window !== undefined ? () => window.document.body : undefined;

  useEffect(() => {
    if (token) setLoadingToken(false);
  }, [token]);

  if (loadingToken) return <Loading height="500px" />;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="default"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Header />
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor="left"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <SidebarLeft />
          </Drawer>
        </Hidden>
        <Hidden mdDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            <SidebarLeft toolbar={classes.toolbar} />
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/admin" exact component={Home} />
          <Route path="/admin/reversal" component={Reversal} />
          <Route path="/admin/report" component={Report} />
          <Route path="/admin/approvals" component={Approvals} />
          <Route path="/admin/gateways" component={Gateways} />
          <Route path="/admin/sites" component={Sites} />
          <Route path="/admin/payment-currency" component={PaymentCurrency} />
          <Route path="/admin/webhook" component={Webhook} />
          <Route path="/admin/bank-information" component={BankInformation} />
        </Switch>
        <footer className={classes.footer}>version: 5.2.0</footer>
      </main>
    </div>
  );
};

export default Routes;
