import React from 'react';
import ReactDOM from 'react-dom';
import App from './layouts/App';
import './i18n';
import ReactGA from 'react-ga4'

ReactGA.initialize(`${process.env.REACT_APP_GOOGLE_ANALYTICS_ID}`)

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
